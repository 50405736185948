import {useEffect, useState} from 'react';

import {hexToRgba} from '../../../Helpers/Helpers';
import {Form} from './Form/Form';
import {Map} from './Map/Map';

export function MapContainer() {
	const [mapData, setMapData] = useState({});
	const [mapType, setMapType] = useState(0);
	const [mapSize, setMapSize] = useState(0);
	const [mapAccuracy, setMapAccuracy] = useState(0);
	const [mapTitle, setMapTitle] = useState(0);

	const [titleInvalid, setTitleInvalid] = useState(false);

	function submitForm(teryts, values, type, source, mainColor, limits = []) {
		if (teryts.length && values.length) {
			setMapData({
				teryts: teryts
					.replace(/ /g, '')
					.split('\n')
					.map((n) => parseInt(n)),
				values: values
					.replace(/ /g, '')
					.split('\n')
					.map((n) => parseInt(n)),
				mainColor: hexToRgba(mainColor),
				source: source,
				limits: limits,
			});
		}
	}
	function resizeMap(size) {
		setMapSize(parseFloat(size));
	}
	function changeMapType(type) {
		setMapType(parseInt(type));
	}
	function changeAccuracy(value) {
		setMapAccuracy(parseInt(value));
	}

	async function generateLink() {
		if (mapTitle.length) {
			setTitleInvalid(false);
			let data = {
				...mapData,
				type: mapType,
				size: mapSize,
				title: mapTitle,
				accuracy: mapAccuracy,
			};
			const response = await fetch('https://us-central1-kartogramy.cloudfunctions.net/app/api/addMap', {
				method: 'POST',
				headers: {'Content-Type': 'application/json'},
				body: JSON.stringify(data),
			});
			const json = await response.json();
			console.log(json);
			return {
				id: json,
				title: mapTitle
					.normalize('NFD')
					.replace(/[\u0300-\u036f]/g, '')
					.split(' ')
					.join('-'),
			};
		} else {
			setTitleInvalid(true);
			return false;
		}
	}

	return (
		<div className="mapContainer">
			<Map data={mapData} type={mapType} mapSize={mapSize} accuracy={mapAccuracy} onTitleChange={setMapTitle} />
			<Form onSubmit={submitForm} onMapResize={resizeMap} onAccuracyChange={changeAccuracy} onTypeChange={changeMapType} onLinkGenerate={generateLink} />

			{titleInvalid && <div className="titleInvalid">Nie podano tytułu mapy!</div>}
		</div>
	);
}

/* Map types:
    - 0: percent
    - 1: classification
*/
