import {useEffect, useRef} from 'react';

import {arraysToObject} from '../../../../Helpers/Helpers';
import InsertIframe from '../InsertIframe';
import Poland from './Poland.js';

export function Map(props) {
	const data = props.data;
	const polandRef = useRef();

	useEffect(() => {
		if (Object.keys(props.data).length !== 0) {
			renderMap();
		}
	}, [props.data]);

	function renderMap() {
		const withLimits = data.type === 1;
		const maxValue = Math.max(...data.values);
		const connectedData = arraysToObject(data.teryts, data.values);

		for (const path of polandRef.current.querySelectorAll('path')) {
			let currentValue = parseInt(connectedData[parseInt(path.id.slice(1))]);
			if (!withLimits) {
				!isNaN(currentValue) ? (path.style.fill = data.mainColor.replace(/[^,]+(?=\))/, currentValue / maxValue)) : (path.style.fill = '#dedede');
			} else {
				const limits = data.limits;
				const color = limits.find((limit) => limit.from <= currentValue && currentValue < limit.to)?.color || '#dddddd';
				path.style.fill = color;
			}
		}
	}

	return (
		<div className="preview">
			<div className="mapContainer preview">
				{Object.keys(data).length === 0 && (
					<div className="loading">
						<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
							<path d="M13.75 22c0 .966-.783 1.75-1.75 1.75s-1.75-.784-1.75-1.75.783-1.75 1.75-1.75 1.75.784 1.75 1.75zm-1.75-22c-1.104 0-2 .896-2 2s.896 2 2 2 2-.896 2-2-.896-2-2-2zm10 10.75c.689 0 1.249.561 1.249 1.25 0 .69-.56 1.25-1.249 1.25-.69 0-1.249-.559-1.249-1.25 0-.689.559-1.25 1.249-1.25zm-22 1.25c0 1.105.896 2 2 2s2-.895 2-2c0-1.104-.896-2-2-2s-2 .896-2 2zm19-8c.551 0 1 .449 1 1 0 .553-.449 1.002-1 1-.551 0-1-.447-1-.998 0-.553.449-1.002 1-1.002zm0 13.5c.828 0 1.5.672 1.5 1.5s-.672 1.501-1.502 1.5c-.826 0-1.498-.671-1.498-1.499 0-.829.672-1.501 1.5-1.501zm-14-14.5c1.104 0 2 .896 2 2s-.896 2-2.001 2c-1.103 0-1.999-.895-1.999-2s.896-2 2-2zm0 14c1.104 0 2 .896 2 2s-.896 2-2.001 2c-1.103 0-1.999-.895-1.999-2s.896-2 2-2z" />
						</svg>
					</div>
				)}

				<div className="map">
					<div className="title">{data.title}</div>
					{Object.keys(data).length > 0 && <Poland ref={polandRef} data={data} />}

					<div className="bottom-left">
						{data.type === 0 && data.values && (
							<div className="legend">
								{Math.max(...data?.values)}
								<div className="bar" style={{backgroundImage: `linear-gradient(to bottom, ${data.mainColor}, rgba(255, 0, 0, 0))`}}></div>
								{Math.min(...data?.values)}
							</div>
						)}
						{data.type === 1 && data.limits && (
							<div className="legend">
								{data.limits.map((limit, i) => {
									const colorStyle = {backgroundColor: limit.color};
									return (
										<div className="label" key={i}>
											<div className="color" style={colorStyle}></div>
											<div className="labelText">{limit.label}</div>
										</div>
									);
								})}
							</div>
						)}
						{data.source && <div className="source">Źródło: {data.source}</div>}
					</div>
				</div>
			</div>
			<InsertIframe url={props.iframeUrl} />
		</div>
	);
}
