import {useEffect, useState} from 'react';
import mapData from './mapData.json';

export function TitleBox(props) {
	const rect = props.rect || {top: 0, left: 0};
	const data = props.data || {};
	const eventData = props.mouseMoveData || {};
	const [positionStyle, setPositionStyle] = useState({top: 0, left: 0});

	useEffect(() => {
		setPositionStyle({
			top: eventData.clientY + 5 - rect.current.getBoundingClientRect().top + 'px',
			left: eventData.clientX + 10 - rect.current.getBoundingClientRect().left + 'px',
		});
	}, [props.rect, props.mouseMoveData]);

	return (
		<>
			{eventData.target?.tagName === 'path' && (
				<div className="titleBox" style={positionStyle}>
					{mapData[eventData.target.id.slice(1)]} <br />
					{Object.keys(data).length !== 0 && <>Wartość: {data.values[data.teryts.indexOf(parseInt(eventData.target.id.slice(1)))] || 'Brak danych'}</>}
					{Object.keys(data).length === 0 && <>Teryt: {eventData.target.id.slice(1)}</>}
				</div>
			)}
		</>
	);
}
