import {useEffect, useState} from 'react';
import './Form.css';

export function Form(props) {
	const resizeMap = props.onMapResize;
	const changeMapType = props.onTypeChange;
	const changeAccuracy = props.onAccuracyChange;

	const [teryts, setTeryts] = useState('');
	const [values, setValues] = useState('');
	const [source, setSource] = useState('');
	const [mapType, setMapType] = useState(0);
	const [mapSize, setMapSize] = useState(1);
	const [mainColor, setMainColor] = useState('#ff8d14');

	const [limits, setLimits] = useState([]);
	//Inputs
	const [limitLabel, setLimitLabel] = useState('');
	const [limitFrom, setLimitFrom] = useState('');
	const [limitTo, setLimitTo] = useState('');
	const [limitColor, setLimitColor] = useState('#ff8d14');

	const [generateLinkVisiblity, setGenerateLinkVisiblity] = useState('');
	const [isFormValid, setFormValid] = useState(false);

	useEffect(() => {
		resizeMap(mapSize);
	}, [mapSize]);

	useEffect(() => {
		changeMapType(mapType);
	}, [mapType]);

	useEffect(() => {
		setGenerateLinkVisiblity(false);
		if (values.length > 0 && teryts.length > 0) {
			setFormValid(true);
			if (mapType === 1 && limits.length === 0) {
				setFormValid(false);
			}
		} else {
			setFormValid(false);
		}
	}, [teryts, values, mapType, mapSize, mainColor, limits]);

	function addLimit() {
		if (limitFrom.length && limitTo.length && limitColor.length && limitLabel.length) {
			setLimits((prevLimits) => {
				const clone = [...prevLimits];
				const id = clone.length ? Math.max(...clone.map((o) => o.id)) + 1 : 0;
				clone.push({
					from: limitFrom,
					to: limitTo,
					label: limitLabel,
					color: limitColor,
					id: id,
				});
				clone.sort((a, b) => a.from - b.from);
				return clone;
			});
		}
	}

	function removeLimit(id) {
		setLimits((prevLimits) => {
			const clone = [...prevLimits];
			const index = clone.findIndex((el) => el.id === id);
			clone.splice(index, 1);
			return clone;
		});
	}

	function submitForm() {
		if (isFormValid) {
			props.onSubmit(teryts, values, mapType, source, mainColor, limits);
			setGenerateLinkVisiblity(true);
		}
	}

	async function generateLink() {
		const linkData = await props.onLinkGenerate();
		if (linkData === false) return;

		const newWindow = window.open('', '_blank');
		newWindow.document.write('<html><head></head><body>Przekierowywanie w toku...</body></html>');
		newWindow.document.close();
		if (linkData.id) {
			newWindow.location = `https://kartogramy.pl/preview/${linkData.id}/${linkData.title}`;
		} else {
			newWindow.document.write('<html><head></head><body>Wystąpił błąd podczas generowania mapy. Spróbuj ponownie.</body></html>');
		}
	}

	return (
		<div className="form">
			<div className="step">
				<div className="description">Wybierz dokładność mapy</div>
				<select className="selectMapAccuracy" onChange={(e) => changeAccuracy(parseInt(e.target.value))}>
					<option value="0">Województwa</option>
					<option value="1">Gminy</option>
				</select>
			</div>
			<div className="step">
				<div className="description">
					Wybierz typ mapy, jaki ma być wyświetlany. Procentowy - przedstawi propocje względem największej wartości. Klasyfikacja - pozwoli Tobie sklasyfikować rejony wg. wartości.
				</div>
				<select value={mapType} className="selectMapType" onChange={(e) => setMapType(parseInt(e.target.value))}>
					<option value="0">Procentowy</option>
					<option value="1">Klasyfikacja</option>
				</select>

				<div className="description">Oraz ustaw rozmiar mapy</div>
				<select value={mapSize} className="selectMapType" onChange={(e) => setMapSize(parseFloat(e.target.value))}>
					<option value="0.5">50%</option>
					<option value="0.75">75%</option>
					<option value="1">100%</option>
					<option value="1.25">125%</option>
					<option value="1.5">150%</option>
					<option value="2">200%</option>
					<option value="4">400%</option>
				</select>
			</div>

			<svg className="arrowSeparator" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
				<path
					d="m9.001 13.022h-3.251c-.412 0-.75.335-.75.752 0 .188.071.375.206.518 1.685 1.775 4.692 4.945 6.069 6.396.189.2.452.312.725.312.274 0 .536-.112.725-.312 1.377-1.451 4.385-4.621 6.068-6.396.136-.143.207-.33.207-.518 0-.417-.337-.752-.75-.752h-3.251v-9.02c0-.531-.47-1.002-1-1.002h-3.998c-.53 0-1 .471-1 1.002z"
					fillRule="nonzero"
				/>
			</svg>
			<div className="step">
				<div className="description">Wprowadź potrzebne dane. Kod TERYT obszaru oraz odpowiadające im wartości. Oddziel je enterem.</div>
				<div className="textareas">
					<textarea onChange={(e) => setTeryts(e.target.value)} value={teryts} placeholder="Kody TERYT"></textarea>
					<textarea onChange={(e) => setValues(e.target.value)} value={values} placeholder="Wartości"></textarea>
				</div>
				<input type="text" className="source" onChange={(e) => setSource(e.target.value)} value={source} placeholder="Źródło danych (opcjonalne)" />
			</div>

			{mapType === 0 && (
				<>
					<svg className="arrowSeparator" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
						<path
							d="m9.001 13.022h-3.251c-.412 0-.75.335-.75.752 0 .188.071.375.206.518 1.685 1.775 4.692 4.945 6.069 6.396.189.2.452.312.725.312.274 0 .536-.112.725-.312 1.377-1.451 4.385-4.621 6.068-6.396.136-.143.207-.33.207-.518 0-.417-.337-.752-.75-.752h-3.251v-9.02c0-.531-.47-1.002-1-1.002h-3.998c-.53 0-1 .471-1 1.002z"
							fillRule="nonzero"
						/>
					</svg>
					<div className="step">
						<div className="description">Podaj główny kolor, według którego ma być generowana mapa.</div>
						<input
							type="color"
							className="selectMainColor"
							value={mainColor}
							onChange={(e) => {
								setMainColor(e.target.value);
							}}
						/>
					</div>
				</>
			)}

			{mapType === 1 && (
				<>
					<svg className="arrowSeparator" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
						<path
							d="m9.001 13.022h-3.251c-.412 0-.75.335-.75.752 0 .188.071.375.206.518 1.685 1.775 4.692 4.945 6.069 6.396.189.2.452.312.725.312.274 0 .536-.112.725-.312 1.377-1.451 4.385-4.621 6.068-6.396.136-.143.207-.33.207-.518 0-.417-.337-.752-.75-.752h-3.251v-9.02c0-.531-.47-1.002-1-1.002h-3.998c-.53 0-1 .471-1 1.002z"
							fillRule="nonzero"
						/>
					</svg>
					<div className="step">
						<div className="description">Podaj zakresy, według których mają być klasyfikowane rejony.</div>
						<div className="limits">
							<div className="add">
								<input type="number" onChange={(e) => setLimitFrom(e.target.value)} value={limitFrom} placeholder="Od" />
								<input type="number" onChange={(e) => setLimitTo(e.target.value)} value={limitTo} placeholder="Do" />
								<input type="text" onChange={(e) => setLimitLabel(e.target.value)} value={limitLabel} placeholder="Etykieta" />
								<input type="color" onChange={(e) => setLimitColor(e.target.value)} value={limitColor} />
								<button onClick={addLimit}>Dodaj limit</button>
							</div>

							<svg className="arrowSeparator" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
								<path
									d="m13.022 14.999v3.251c0 .412.335.75.752.75.188 0 .375-.071.518-.206 1.775-1.685 4.945-4.692 6.396-6.069.2-.189.312-.452.312-.725 0-.274-.112-.536-.312-.725-1.451-1.377-4.621-4.385-6.396-6.068-.143-.136-.33-.207-.518-.207-.417 0-.752.337-.752.75v3.251h-9.02c-.531 0-1.002.47-1.002 1v3.998c0 .53.471 1 1.002 1z"
									fillRule="nonzero"
								/>
							</svg>

							<div className="list">
								{limits.map((limit, i) => {
									const colorStyle = {backgroundColor: limit.color, padding: '10px'};
									return (
										<div className="limit" key={i}>
											<div className="color" style={colorStyle}></div>
											<div className="numbers">
												<div className="from">{limit.from}</div>-<div className="to">{limit.to}</div>
											</div>
											<div className="label">{limit.label}</div>
											<button className="remove" onClick={() => removeLimit(limit.id)}>
												<svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
													<path d="m12 10.93 5.719-5.72c.146-.146.339-.219.531-.219.404 0 .75.324.75.749 0 .193-.073.385-.219.532l-5.72 5.719 5.719 5.719c.147.147.22.339.22.531 0 .427-.349.75-.75.75-.192 0-.385-.073-.531-.219l-5.719-5.719-5.719 5.719c-.146.146-.339.219-.531.219-.401 0-.75-.323-.75-.75 0-.192.073-.384.22-.531l5.719-5.719-5.72-5.719c-.146-.147-.219-.339-.219-.532 0-.425.346-.749.75-.749.192 0 .385.073.531.219z" />
												</svg>
											</button>
										</div>
									);
								})}
							</div>
						</div>
					</div>
				</>
			)}
			<div className="navigation">
				<button onClick={() => submitForm()} disabled={!isFormValid}>
					Generuj mapę
				</button>
				{generateLinkVisiblity && <button onClick={generateLink}>Stwórz mapę z linkiem</button>}
			</div>
		</div>
	);
}

/* Example:
18
12
24
16
2
26
6
10
14
30
8
4
20
32
28
22

352
155
20
369
96
55
88
74
200
355
72
22
99
341
299
150

201011
201022
201032
201043
201052
201062
202011
202021
202033
202041
202052
202062
202073
203011
203022
203032
203042
203052
203062
204013
204022

352
155
20
369
96
55
88
74
200
355
72
22
99
341
299
150
97
118
254
65
99

*/
