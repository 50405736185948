import {BrowserRouter as Router, Routes, Route, Link} from 'react-router-dom';
import {useEffect, useState} from 'react';
import ReactGA from 'react-ga';

import {MapContainer} from './Componensts/Pages/Main/MapConteiner';
import {NotFound404} from './Componensts/Pages/NotFound404';
import Contact from './Componensts/Pages/Contact/Contact';
import {Preview} from './Componensts/Pages/Preview/Preview';
import './App.css';

ReactGA.initialize('G-SY50FFCBXL');

function App() {
	const [inIframe, setInIframe] = useState(false);
	useEffect(() => {
		console.log('Aaa');
		console.log(!(window === window.parent));
		setInIframe(!(window === window.parent));
	}, []);

	return (
		<Router>
			<div className={inIframe ? 'App iframe' : 'App'}>
				{!inIframe && (
					<div className="navbar">
						<Link to="/">
							<div className="logo">
								<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128">
									<path
										fill="#015BBA"
										opacity="1"
										d="M27 95c.8-3.3-.6-5.2-4-6-2.3-.8-4.5-2.1-6.8-3-3.8-1.7-5-4-5.5-8.6-1.4-11-4.4-21.7-6.7-32.5-.2-1-.6-2.6 0-3.2 3.1-4.2 2.4-8.5.6-12.7-1.3-3.2 0-4.6 3-5.4L25.7 19a12 12 0 0 0 5.1-2.8c8.1-8 17.7-9.2 29.4-7l-7.4 4.4c6 1.7 11.3 6.7 18 2.5 1.4-1 4 .1 6.2.2l21.7.3c1.7 0 3.3-.4 5-.5 8-.4 10.6 1.8 11.7 10.8-17.8 14-35.2 27.6-52.8 41C51 77.2 39 86 27 95.2z"
									/>
									<path
										fill="#FDC401"
										opacity="1"
										d="M27 95.4C39 86 50.8 77.1 62.6 68l52.8-40.7c4.9 6.4 5.6 14 6.1 19.8l-10.2 11.3 8.8 3c-1.5 8 .6 15 4.5 22 2.8 5 1.5 9.6-2.7 13.7-2.8 2.8-5.3 6-7.5 9.3-3.2 4.8-6.4 9.9-1.3 17.1-2.5-1-3.4-1.2-4.1-1.7-8-6-16.9-5.9-25.7-3.7-6.4 1.7-11.6 2-15.2-4.5l-6.3 4c-1.6-2.6-2.7-5.5-4.8-7.5-2.3-2.3-5.3-3.7-8.1-5.4-1-.6-2-.8-2.9-1.5l-8.9-7.4-4.8 6.7-2-1.1c-1.2-1.9-2.4-3.7-3.5-6z"
									/>
								</svg>
								<div className="url">kartogramy.pl</div>
							</div>
						</Link>
						<div className="right">
							<Link to="/contact">Kontakt</Link>
							<Link to="/" className="action">
								Stwórz mapę
							</Link>
						</div>
					</div>
				)}
				<Routes>
					<Route exact path="/" element={<MapContainer />}></Route>
					<Route exact path="/contact" element={<Contact />}></Route>
					<Route exact path="/preview/:id" element={<Preview />}></Route>
					<Route exact path="/preview/:id/:title" element={<Preview />}></Route>
				</Routes>
				{!inIframe && (
					<div className="footer">
						<div className="about">
							<div className="header">O projekcie</div>
							Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia, molestiae quas vel sint commodi repudiandae consequuntur voluptatum laborum numquam blanditiis
							harum quisquam eius sed odit fugiat iusto fuga praesentium optio, eaque rerum! Provident similique accusantium nemo autem.
						</div>
						<div className="navigation">
							<div className="header">Nawigacja</div>
							<ul>
								<li>
									<Link to="/">Strona główna</Link>
								</li>
								<li>
									<Link to="/contact">Kontakt</Link>
								</li>
								<li>
									<Link to="/">Stwórz mapę</Link>
								</li>
							</ul>
						</div>
					</div>
				)}
			</div>
		</Router>
	);
}

export default App;

/* TODO:
    - Set stroke to none

    const el = document.querySelectorAll("path")
    [...el].map(e=>{
        return {"d": e.getAttribute("d"), "id": e.id}
    })
*/
