import {forwardRef, useState} from 'react';
import {ReactComponent as MunicipalitiesSvg} from './Svgs/municipalities.svg';
import {ReactComponent as VoivodeshipsSvg} from './Svgs/voivodeships.svg';
import {TitleBox} from './TitleBox';

const Poland = forwardRef((props, ref) => {
	const data = props.data;
	const mapSize = props.mapSize;
	const accuracy = props.accuracy;

	const [mouseMoveData, setMouseMoveData] = useState({});

	const mapStyle = {width: 500 * mapSize + 'px', height: 500 * mapSize + 'px'};

	return (
		<div className="mapSvg">
			{accuracy === 0 && <VoivodeshipsSvg ref={ref} onMouseMove={(e) => setMouseMoveData(e)} style={mapStyle} />}
			{accuracy === 1 && <MunicipalitiesSvg ref={ref} onMouseMove={(e) => setMouseMoveData(e)} style={mapStyle} />}
			<TitleBox mouseMoveData={mouseMoveData} data={data} rect={ref} />
		</div>
	);
});

export default Poland;
