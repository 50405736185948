import {forwardRef, useState} from 'react';
import {ReactComponent as MunicipalitiesSvg} from './Svgs/municipalities.svg';
import {ReactComponent as VoivodeshipsSvg} from './Svgs/voivodeships.svg';
import {TitleBox} from './TitleBox';

const Poland = forwardRef((props, ref) => {
	const data = props.data;
	const [mouseMoveData, setMouseMoveData] = useState({});

	const mapStyle = {width: 500 * data.size + 'px', height: 500 * data.size + 'px'};

	return (
		<div className="mapSvg">
			{data.accuracy === 0 && <VoivodeshipsSvg ref={ref} onMouseMove={(e) => setMouseMoveData(e)} style={mapStyle} />}
			{data.accuracy === 1 && <MunicipalitiesSvg ref={ref} onMouseMove={(e) => setMouseMoveData(e)} style={mapStyle} />}
			{Object.keys(data).length && <TitleBox mouseMoveData={mouseMoveData} data={data} rect={ref} />}
		</div>
	);
});

export default Poland;
