import {useEffect, useRef, useState} from 'react';

import {arraysToObject} from '../../../../Helpers/Helpers';
import Poland from './Poland.js';
import './Map.css';

export function Map(props) {
	const data = props.data;
	const type = props.type;
	const mapSize = props.mapSize;
	const accuracy = props.accuracy;
	const polandRef = useRef();
	const [mapTitle, setMapTitle] = useState('');

	useEffect(() => {
		if (Object.keys(props.data).length !== 0) {
			renderMap();
		}
		console.log(props.type);
	}, [props.data, props.type]);

	useEffect(() => {
		props.onTitleChange(mapTitle);
	}, [mapTitle]);

	function renderMap() {
		const withLimits = type === 1;
		const maxValue = Math.max(...data.values);
		const connectedData = arraysToObject(data.teryts, data.values);

		console.log(connectedData);
		for (const path of polandRef.current.querySelectorAll('path')) {
			let currentValue = parseInt(connectedData[parseInt(path.id.slice(1))]);
			if (!withLimits) {
				!isNaN(currentValue) ? (path.style.fill = data.mainColor.replace(/[^,]+(?=\))/, currentValue / maxValue)) : (path.style.fill = '#dedede');
			} else {
				const limits = data.limits;
				const color = limits.find((limit) => limit.from <= currentValue && currentValue < limit.to)?.color || '#dddddd';
				path.style.fill = color;
			}
		}
	}

	return (
		<div className="map">
			<textarea className="mapTitle" value={mapTitle} rows="1" placeholder="Podaj tytuł mapy" onChange={(e) => setMapTitle(e.target.value)}></textarea>
			<Poland ref={polandRef} data={data} mapSize={mapSize} accuracy={accuracy} />

			<div className="bottom-left">
				{type === 0 && data.values && (
					<div className="legend">
						{Math.max(...data?.values)}
						<div className="bar" style={{backgroundImage: `linear-gradient(to bottom, ${data.mainColor}, rgba(255, 0, 0, 0))`}}></div>
						{Math.min(...data?.values)}
					</div>
				)}
				{type === 1 && data.limits && (
					<div className="legend">
						{data.limits.map((limit, i) => {
							const colorStyle = {backgroundColor: limit.color};
							return (
								<div className="label" key={i}>
									<div className="color" style={colorStyle}></div>
									<div className="labelText">{limit.label}</div>
								</div>
							);
						})}
					</div>
				)}
				{data.source && <div className="source">Źródło: {data.source}</div>}
			</div>
		</div>
	);
}
