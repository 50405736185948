import {useEffect, useState, useRef} from 'react';
import {useParams} from 'react-router-dom';
import {Map} from './Map/Map';

export function Preview() {
	const [data, setData] = useState({});
	const {id, title} = useParams();

	useEffect(() => {
		if (id) {
			fetch('https://us-central1-kartogramy.cloudfunctions.net/app/api/getMap/' + id)
				.then((response) => response.json())
				.then((res) => {
					document.title = `Kartogramy.pl - ${res.data.title}`;
					setData(res.data);
				})
				.catch((err) => console.error(err));
		}
	}, []);

	return <Map data={data} iframeUrl={`https://kartogramy.pl/preview/${id}/${title}`} />;
}
